import { ShieldCheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Spinner from "@sid/core/components/icon/Spinner";
import { useAppContext } from "@sid/core/context/AppContext";
import { useUserContext } from "@sid/core/context/UserContext";
import api from "@sid/core/util/api";
import homeDomain from "@sid/core/vars/homeDomain";
import axios from "axios";
import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "next-i18next";
import { useMemo } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Button from "./Button";
import InputCaptcha from "./InputCaptcha";
import ModalComponent, { useModal } from "./Modal";

const ClaimMicrosite = ({ onFocus }) => {
  const modal = useModal();
  const captchaModal = useModal();
  const { status } = useUserContext();
  const { t } = useTranslation();

  const { alternate } = useAppContext();

  const onSubmit = async (values, { setValues }) => {
    if (values.captcha === "") {
      captchaModal.setOpen(true);
      return;
    }
    // await new Promise((s) => setTimeout(s, 1000));
    try {
      const { data } = await api().post("claim-microsite", {
        slug: values.name,
        captcha: values.captcha,
      });

      setValues({
        ...values,
        _name: values.name,
        captcha: "",
        available: data?.data.available,
      });
      modal.setOpen(true);
    } catch (e) {
      setValues({ ...values, captcha: "" });

      if (axios.isAxiosError(e)) {
        if (e.response?.data.errors) {
          const msg = e.response?.data.errors.map((t) => t.reason).join(" ");
          if (/already taken/i.test(msg)) {
            setValues({ ...values, available: false, captcha: "" });
            modal.setOpen(true);
          } else {
            toast.error(msg || t("connection_error"));
          }
        } else {
          toast.error(e.response?.data.message || t("connection_error"));
        }
      }
    }
  };

  const validate = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[a-zA-Z0-9-_]+$/, { message: t("invalid_short_format") })
          .required(),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={{ name: "", captcha: "", available: false }}
      validationSchema={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <Form className="mx-6 relative">
          <div className="mx-auto max-w-[550px] bg-white text-lg md:text-2xl font-montserrat font-semibold p-1 rounded-md flex items-center">
            <div
              className={clsx("pl-2 transition-all", {
                "opacity-50": isSubmitting,
              })}
            >
              <span className="text-red-500">s</span>.id/
            </div>
            <Field
              type="text"
              name="name"
              className={clsx("w-full mx-2 transition-all", {
                "opacity-50": isSubmitting,
              })}
              placeholder={t("microsite_name_placeholder")}
              onFocus={onFocus}
              disabled={isSubmitting}
            />
            <button
              className={clsx(
                "bg-blue-600 hover:bg-blue-700 text-white p-1 px-2 md:px-3 md:py-2 font-semibold rounded-md flex items-center",
                {
                  "opacity-50 !bg-blue-600": isSubmitting,
                }
              )}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner className="w-4 md:w-7 mr-2 animate-spin" />
              ) : (
                <ShieldCheckIcon className="w-4 md:w-7 mr-2" />
              )}
              <Trans i18nKey="landing:microsite_feat.button" />
            </button>
            <ModalComponent {...modal} className="max-w-[500px]">
              <div className="text-center">
                <h1 className="text-lg md:text-3xl font-montserrat font-bold text-left md:text-center">
                  {values.available ? (
                    <>
                      <Trans i18nKey="congratulations">Congratulations</Trans>{" "}
                      🎉
                    </>
                  ) : (
                    <>
                      <Trans i18nKey="unfortunately">Unfortunately</Trans> 😞
                    </>
                  )}
                </h1>
                <button
                  className="p-2 absolute top-2.5 right-2.5"
                  onClick={modal.close}
                >
                  <XMarkIcon className="w-6 md:w-7" />
                </button>
                <div className="text-xl md:text-4xl my-3 font-bold font-montserrat text-gray-800 bg-white shadow-xl p-2 rounded-lg border overflow-hidden truncate">
                  <span className="text-red-500">s</span>.id/
                  <span className="underline text-gray-900 truncate">
                    {values.name}
                  </span>
                </div>
                <h2
                  className={clsx(
                    "text-lg md:text-2xl font-semibold font-montserrat",
                    {
                      "text-red-500": !values.available,
                    }
                  )}
                >
                  {values.available ? (
                    <Trans i18nKey="is_available">Is available!</Trans>
                  ) : (
                    <Trans i18nKey="not_available">Is not available!</Trans>
                  )}
                </h2>
                {values.available ? (
                  <>
                    <p className="my-2 md:my-3 text-sm md:text-base">
                      <Trans i18nKey="signup_create_microsite">
                        Sign Up and create your <strong>Microsite</strong> right
                        now!
                      </Trans>
                    </p>
                    <Button
                      color="blue"
                      className="text-lg w-full"
                      onClick={() => {
                        if (status === "authenticated") {
                          //@ts-ignore
                          window.location =
                            (alternate ? homeDomain : "") +
                            "/dashboard/microsite?utm_source=sid-landing&utm_campaign=claim-microsite&createWithName=" +
                            values.name;
                        } else {
                          //@ts-ignore
                          window.location =
                            (alternate ? homeDomain : "") +
                            "/auth/register?utm_source=sid-landing&utm_campaign=claim-microsite&name=" +
                            values.name;
                        }
                      }}
                    >
                      <Trans i18nKey="start_for_free_format">
                        Start for <strong>Free!</strong>
                      </Trans>{" "}
                      🤩
                    </Button>
                  </>
                ) : (
                  <p className="mt-2">
                    <Trans i18nKey="please_try_another_name">
                      Please Try another name
                    </Trans>
                  </p>
                )}
              </div>
            </ModalComponent>
          </div>
          <ErrorMessage name="name">
            {(msg) => (
              <div className="max-w-[550px] mx-auto relative">
                <div className="bg-red-500 text-white absolute top-1 rounded-md px-4 py-2 w-full">
                  {msg}
                </div>
              </div>
            )}
          </ErrorMessage>
          <ModalComponent {...captchaModal}>
            <div className="flex">
              <h1 className="text-2xl flex-1 font-montserrat font-semibold">
                🤖 {t("solve_captcha")}
              </h1>
              <button onClick={() => captchaModal.setOpen(false)}>
                <XMarkIcon className="w-6" />
              </button>
            </div>
            <p className="my-1 font-worksans">{t("please_solve_captcha")}</p>
            <div className="flex justify-center my-10">
              <InputCaptcha
                action="claim_microsite"
                onSuccess={() => {
                  captchaModal.setOpen(false);
                  setTimeout(() => {
                    handleSubmit();
                  }, 500);
                }}
              />
            </div>
          </ModalComponent>
        </Form>
      )}
    </Formik>
  );
};

export default ClaimMicrosite;
