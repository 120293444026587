import clsx from "clsx";
import { Trans } from "next-i18next";
import { Fragment, useMemo } from "react";
import styled from "./LandingSection3.module.scss";

import useSWR from "swr";

// const showcases = [
//   { image: "s.id_pandi.png", slug: "pandi" },
//   { image: "s.id_kurikulum-merdeka.png", slug: "kurikulum-merdeka" },
//   { image: "s.id_adg.png", slug: "adg" },
//   { image: "s.id_dewabiz.png", slug: "dewabiz" },
//   { image: "s.id_psi.png", slug: "psi" },
//   { image: "s.id_naupaw.png", slug: "naupaw" },
//   { image: "s.id_micky.png", slug: "micky" },
// ];

const LandingSection3 = () => {
  const { data, isLoading } = useSWR("/landing/microsites", {
    errorRetryCount: 0,
    refreshInterval: 0,
  });

  const showcases = useMemo(() => {
    if (data) {
      return data.data?.data || [];
    }
    return [];
  }, [data]);

  return (
    <Fragment>
      <section className="text-white mt-28 text-center">
        {isLoading ? (
          <Fragment>
            <div className="w-64 h-8 skeleton mx-auto" />
            <div className="w-96 h-5 skeleton mt-5 mx-auto mb-20" />
          </Fragment>
        ) : (
          <Fragment>
            <h1 className="text-xl md:text-5xl font-bold font-montserrat leading-tight">
              🧭{" "}
              <Trans i18nKey="landing:explore_the_microsite">
                Explore the Microsite
              </Trans>
            </h1>
            <p className="md:text-xl md:mt-3 leading-relaxed font-worksans px-6">
              <Trans i18nKey="landing:explore_the_microsite_description">
                See who use s.id microsite for they own purposes.
              </Trans>
            </p>
          </Fragment>
        )}
        <div className="-mx-6">
          {isLoading ? (
            <div
              className={clsx(
                styled.ScrollItem,
                "w-full md:mt-5 rounded-2xl overflow-x-hidden flex gap-4"
              )}
            >
              <div className="w-72 shrink-0">
                <div className="pb-[170%] bg-gray-500 animate-pulse rounded-lg"></div>
              </div>
              <div className="w-72 shrink-0">
                <div className="pb-[170%] bg-gray-500 animate-pulse rounded-lg"></div>
              </div>
              <div className="w-72 shrink-0">
                <div className="pb-[170%] bg-gray-500 animate-pulse rounded-lg"></div>
              </div>
              <div className="w-72 shrink-0">
                <div className="pb-[170%] bg-gray-500 animate-pulse rounded-lg"></div>
              </div>
              <div className="w-72 shrink-0">
                <div className="pb-[170%] bg-gray-500 animate-pulse rounded-lg"></div>
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                styled.ScrollItem,
                "w-full md:mt-5 rounded-2xl overflow-x-hidden"
              )}
            >
              {[...new Array(2)].map((_, i) => (
                <div
                  className={clsx("", styled.ItemGroup)}
                  style={{
                    animationDuration: data?.data?.speed
                      ? data?.data?.speed + "s"
                      : undefined,
                  }}
                  key={i}
                >
                  {showcases.map((item, i) => (
                    <div className="w-72" key={i}>
                      <a
                        className={clsx(
                          "w-full bg-white rounded-2xl overflow-hidden relative block",
                          styled.Item
                        )}
                        href={"https://s.id/" + item.slug}
                        target="_blank"
                      >
                        <img
                          src={
                            item.image?.startsWith("https://")
                              ? item.image
                              : `/images/landing/showcase/${item.image}`
                          }
                          alt={item.slug}
                        />
                        <div className={clsx(styled.nameOuter)}>
                          <div
                            className={clsx(
                              "bg-white text-black shadow-xl pr-4 pl-3 font-semibold py-1 rounded-full",
                              styled.name
                            )}
                          >
                            <img
                              src="/images/sid-neu-logo-single.svg"
                              alt="s.id"
                              className="h-5 inline-block mr-1 -mt-0.5"
                            />
                            s.id/{item.slug}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <Button
        roundFull
        className="font-semibold mt-6 text-lg font-montserrat"
        color="green"
      >
        See All Featured Microsite
      </Button> */}
      </section>

      {/* <section className="text-white md:mt-28 text-center">
        <h1 className="text-xl md:text-5xl font-bold font-montserrat leading-tight px-6">
          🍫{" "}
          <Trans i18nKey="landing:microsite_templates">
            Microsite Templates
          </Trans>
        </h1>
        <p className="md:text-xl md:mt-3 leading-relaxed font-worksans px-6">
          <Trans i18nKey="landing:microsite_templates_description">
            Explore and browse the template curated by our team.
          </Trans>
        </p>
        <div
          className="bg-[#371e15] pb-[75%] md:pb-[30%] md:rounded-2xl mt-8 relative bg-cover bg-center"
          style={{
            backgroundImage: `url(/images/template-landing.png)`,
          }}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <Link href="/templates" passHref>
              <button className="p-2 border text-xl font-montserrat rounded-lg px-5 font-bold hover:bg-white hover:text-black transition-all">
                <Trans i18nKey="landing:explore_more">Explore More</Trans>
              </button>
            </Link>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
};

export default LandingSection3;
