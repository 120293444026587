import { Trans } from "next-i18next";
import { Fragment } from "react";

const LandingSection2 = () => {
  // const scrollRef = useRef(null);
  // const { x, y } = useScroll(scrollRef);

  // const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  return (
    <Fragment>
      <section className="text-white flex flex-col mt-16 md:mt-28">
        <div className="w-full text-center">
          <p className="uppercase font-bold bg-blue-600 text-white inline-block px-2 py-0.5 rounded-md mb-4 text-sm">
            <Trans i18nKey="microsite">Microsite</Trans>
          </p>

          <h1 className="text-xl md:text-6xl font-bold font-montserrat leading-tight p-3">
            <Trans i18nKey="landing:section_microsite_heading">
              Compact, Elegant, and Flexible
            </Trans>
          </h1>

          <p className="md:text-xl md:mt-3 leading-relaxed font-worksans px-3">
            <Trans i18nKey="landing:section_microsite_description">
              Create a seamless online hub that adapts to your needs, allowing
              you to share multiple links through a single, visually appealing
              landing page. Elevate your digital identity with a compact,
              elegant, and flexible <strong>Microsite</strong>.
            </Trans>
          </p>
          {/* <Button className="text-xl mt-6 font-semibold" color="blue" roundFull>
          Learn More
        </Button> */}
        </div>
        <div className="pt-12 md:-mx-12 -mt-8">
          <img
            src={"/images/landing/landing-microsite-0.png"}
            alt=""
            className="w-full"
          />
        </div>
      </section>
    </Fragment>
  );
};

export default LandingSection2;
